.tabs {
  &__list {
    display: flex;
    justify-content: left;
		margin-top: 16px;
		padding-bottom: 17px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  &__btn {
    font-weight: 400;
    text-align: center;
    border: none;
    cursor: pointer;
		padding: 0;
		margin-right: 16px;
    background-color: transparent;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    line-height: 16px;
		color: #4D4E50;
		box-sizing: content-box;

		&.active {
      outline: none;
      border-bottom: 1px solid #4D4E50;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  &.big {
    .tabs__btn {
      font-size: 20px;
    }
  }

  &.small {
    .tabs__btn {
      font-size: 16px;
    }
  }
}


