.prize-pools {
  padding-top: 16px;
  padding-bottom: 16px;

  p {
    color: #727476;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    width: 100%;
    margin: 0 0 16px 0;
    padding: 0;
  }

  &__header {
    display: flex;
    margin-bottom: 10px;
    justify-content: flex-end;
    align-items: center;

    &--dropdown {
      width: 240px;
    }
  }

  &__body {
    height: 320px;

    a {
      display: block;
      margin-top: 26px;
      margin-bottom: 30px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      text-decoration: none;
      color: #88C559;
    }
  }
}
