.btn {
  font-family: inter-semibold, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  box-sizing: border-box;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  height: 32px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;

  svg {
    margin-right: 4px;
  }

  &.primary {
    color: #FFF;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    background: #197B6F;

    &:hover {
      background: #07584E;
    }
  }

  &.secondary {
    color: #197B6F;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: #FFF;
    height: 33px;

    &:hover {
      background: #F9FAFC;
    }
  }

  &.danger {
    color: #FFF;
    border-radius: 2px;
    border: 1px solid #DD3F3F;
    background: #DD3F3F;
    height: 33px;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &.btn-table {
    height: 36px;
    padding-left: 16px;
    padding-right: 16px;
  }

  &.btn-small {
    height: 24px;
    padding: 4px 8px;
    margin-left: 8px;
  }

  &.with-errors {
    top: -20px;
    position: relative;
  }
}

/* Button loader
--------------------------------- */
.small-spinner {
  animation: rotate 2s linear infinite;
  width: 20px;
  height: 20px;

  & .path {
    stroke: #FFFFFF;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
