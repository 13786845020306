.alert {
  position: relative;
  z-index: 50;
	font-family: Inter, sans-serif;

	&__backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .8);
  }

  &__container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__panel {
		width: 420px;
    background: #FFFFFF;
    border-radius: 16px;
    padding: 16px;

    h2 {
      margin: 0;
      font-weight: 600;
      font-size: 16px;
      color: #4D4E50;
      position: relative;
			line-height: 24px;
    }

    svg {
      width: 16px;
      height: 16px;

      &.primary {
        path {
          fill: #88C559;
        }
      }

      &.danger {
        path {
          fill: #FF5F6D;
        }
      }
    }
  }

  &__close {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }

  &__description {
    text-align: left;
    color: #727476;
		padding: 8px 0;
		font-size: 14px;
		font-weight: 400;
		line-height: 16px;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }
}
