.pool-details {
  display: block;
  font-family: Inter, sans-serif;
  max-height: 460px;

  &__top {
    width: 100%;

    &__input {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-bottom: 16px;
      .input-group {
        width: 360px;

      }
    }

    &__description {
      width: 100%;
      display: block;
      padding-bottom: 16px;

      label {
        color: #4D4E50;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }

      textarea {
        height: 32px;
        padding: 8px;
        background: #E8E9EB;
        border-radius: 0;
        margin-top: 4px;
        font-family: Inter, sans-serif;
      }
    }

    &__bottom-input {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-bottom: 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      .input-group {
        width: 360px;

        &:nth-child(2) {
          input {
            margin-top: 4px;
            padding-right: 48px;
          }

          span {
            position: relative;
          }

          span:before {
            position: absolute;
            content: "days";
            top: 30px;
            right: 14px;
            color: #9D9EA1;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
          }
        }

        .dropdown {
          margin-top: 4px;
        }
      }

      .dropdown {
        width: 360px !important;
      }
    }

    p {
      color: #727476;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      width: 100%;
      margin: 0;
      padding: 16px 0 16px 0;
    }

    .info {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0;

      p {
        margin: 0;
        padding: 8px 0 0 0;
      }

      svg {
        padding-top: 8px;
      }
    }

    .input-group {
      width: 360px;



      textarea {
        height: 32px;
        padding: 8px;
        background: #E8E9EB;
        border-radius: 0;
        margin-top: 4px;

        &:focus,
        &:focus-visible {
          outline: 2px solid rgb(148, 229, 220);
        }
      }

      input {
        height: 32px;
        padding: 8px;
        background: #E8E9EB;
        border-radius: 0;

        &:focus,
        &:focus-visible {
          outline: none;
        }
      }

      label {
        color: #4D4E50;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }

      .required {
        color: #DD3F3F;
        font-family: Inter, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        margin-left: 4px;
      }
    }
  }

  &__bottom {
    width: 100%;
    height: 144px;
    display: flex;
    justify-content: space-between;
    padding-top: 16px;

    &--input:last-child {
      display: flex;
      align-items: center;
      justify-content: right;

      input {
        width: 50px;
        margin-right: 20px;
        margin-left: 20px;
      }

      span {
        font-size: 16px;
        line-height: 1.6;
        color: rgba(0, 0, 0, 0.5)
      }
    }
  }
}
