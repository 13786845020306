.image-container {
  width: 340px;
  display: flex;
  justify-content: space-between;

  .upload {
    width: 160px;
    height: 120px;
    background: #E8E9EB;
		display: flex;
		justify-content: center;

    img {
      border-radius: 5px;
      max-width: 100%;
      max-height: 100%;
      display: inline-block;
      object-fit: fill;
      overflow: hidden;
      height: 132px;
      text-align: center;
      background: #F0F0F3;
    }
  }

  .prizes-file-upload {
    display: block;
    font-family: inter-semibold, sans-serif;
    margin: 0 0px 0 16px;
    width: 50%;

    input[type="file"] {
      display: none;
    }

    input[type="text"] {
      width: 415px;
    }

    .title {
      color: #62698A;
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      margin: 0;
    }

    .size {
      color: #62698A;
      line-height: 16px;
      margin: 12px 0;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      font-family: inter-regular, sans-serif;
    }

    label {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      box-sizing: border-box;
      box-shadow: none;
      cursor: pointer;
      display: flex;
      height: 32px;
      width: 107px;
      padding: 4px 4px;
      margin-top: 12px;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      color: #197B6F;
      border: 1px solid rgba(0, 0, 0, 0.15);
      background: #FFF;

      &.file {
        color: #727476;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;

				&.selected {
					width: 90%;
				}
      }

      p {
        width: 98px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    p {
      color: #727476;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin-left: 8px;
    }
  }
}



