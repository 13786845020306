.common-code-batch {
    margin-top: 2rem;
}

.common-code-batch__row {
    width: 100%;
    display: flex;
    margin-bottom: 16px;
    justify-content: space-between;
    align-items: flex-end;

    .btn {
        width: 170px;
    }

    &.bottom {
        padding: 0 2px;
        align-items: flex-start;
    }
}

.common-code-batch__form-control {
    display: block;
    align-items: center;
}

.common-code-batch__form-control--barcode {
    display: block;
    width: 534px;
}

.form-control__label {
    color: #4D4E50;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    position: relative;
    bottom: 4px;
}

.common-code-batch__form-control--codes-amount {
    width: 170px;
}


.common-code-batch__form-control--barcode-type button {
    width: 170px;
}

.common-code-batch__form-control--expiration {
    width: 170px;
    label {
        margin-left: 0;
    }

    svg {
        margin-right: 10px;
    }

    .react-datepicker-wrapper {
        width: auto;

        input {
            background: #E8E9EB;
            border: none;
            font-size: 14px;
            line-height: 1;
            color: rgba(0, 0, 0, 0.5);
            width: 100%;
            display: flex;
            height: 32px;
            padding: 10px 16px 10px 8px;
            align-items: center;
            align-self: stretch;

            &:focus,
            &:focus-visible {
                outline: 2px solid rgb(148, 229, 220);
            }
        }
    }

    .react-datepicker__day {
        &--keyboard-selected {
            background-color: #197B6F;
        }

        &--selected {
            background-color: #197B6F;
        }
    }
}

.common-code-batch__form-control--validity {
    width: 170px;

    span {
        position: relative;
    }

    span:before {
        position: absolute;
        content: "min";
        top: 26px;
        left: 43px;
        color: #9D9EA1;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
    }
}
