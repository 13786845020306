.info {
	width: 100%;
	display: flex;
	align-items: center;
	margin: 0;
	color: #858689;
	font-family: Inter, sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 16px;
	padding: 16px 0;
}

.limits {
  .error-message {
    position: relative;
    top: -30px;
  }

  &__top {
    margin-bottom: 20px;
  }

  &__type, &__probability {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 400px;

    .label-inside {
      position: relative;

      span {
        position: absolute;
        color: #9D9EA1;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        top: 8px;
        left: 74px;
        font-family: Inter, sans-serif;
      }

      &__prizes {
        span {
          left: 66px;
        }
      }

      input {
        padding-right: 48px;
      }
    }

    input {
      width: 112px;
    }

    .dropdown {
      width: 300px;

      &__btn {
        margin: 0;
        color: #4D4E50;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px
      }
    }
  }

  &__bottom, &__top {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__duration, &__perPlay {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    span {
      margin-right: 8px;
      color: #4D4E50;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
    }

    input {
      width: 112px;
    }

    .label-inside {
      position: relative;

      input {
        padding-right: 48px;
      }
    }

    .label-inside {
      &:before {
        position: absolute;
        top: 7px;
        right: 14px;
        color: #9D9EA1;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
      }

      &__days:before {
        content: "days";
      }

      &__plays:before {
        content: "plays";
      }
    }
  }

  &__bottom {
    margin-bottom: 20px;
  }
}
