.notification-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.notification {
  display: flex;
  position: fixed;
  align-items: center;
  z-index: 99;
  min-width: 300px;
  padding: 8px 16px;
  justify-content: space-between;
  border: none;
  transition: all 0.5s;

  &.hidden{
    transform: translateY(-120px);
  }

  &--message {
    width: 97%;
    text-align: center;

    p {
      font-family: "Inter", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin: 0;
    }
  }

  &.success {
    background-color: #C9F7A7;
    border-radius: 8px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.20);
    color: #438A18;
    transform: translateY(32px);

    svg {
      path {
        stroke: #438A18;
      }
    }
  }

  &.error {
    background-color: #FD9696;
    border-radius: 8px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.20);
    color: #951212;
    transform: translateY(32px);

    svg {
      path {
        stroke: #951212;
      }
    }
  }

  &.warning {
    background-color: #FFF278;
    border-radius: 8px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.20);
    color: #959816;
    transform: translateY(32px);

    svg {
      path {
        stroke: #959816;
      }
    }
  }

  svg {
    &:hover {
      cursor: pointer;
    }
  }
}
