.input {
  background: #E8E9EB;
  border: none;
  font-size: 14px;
  line-height: 1;
  color: #4D4E50;
  width: 100%;
  display: flex;
  height: 32px;
  padding: 10px 16px 10px 8px;
  align-items: center;
  align-self: stretch;


  &[type=number] {
	padding-right: 4px;
	padding-left: 10px;
  }


  &::placeholder {
	font-size: 14px;
	line-height: 14px;
	color: rgba(55, 63, 65, 0.3);
  }

  &:focus,
  &:focus-visible {
	border: 2px solid rgb(148, 229, 220);
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.error {
  border: 1px solid #FF5F6D;
}
