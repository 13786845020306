.pools {
  padding: 24px;
  border-radius: 16px;
  background: #FFF;

  &__header {
    width: 100%;
    height: 56px;
    padding: 8px 0;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  &__header--left {
    width: 33%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    p {
      color: #727476;
      font-family: inter-semibold, sans-serif;
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }

    .search {
      margin-left: 24px;
    }
  }

  &__header--right {
    width: 33%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button svg {
      width: 24px;
      height: 24px;
      margin: 0 8px 0 0;
    }
  }

  &__header--center {
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.copy-pool {
  padding: 8px;

  .generate-codes {
    display: flex;
    align-items: center;

    p {
      margin-left: 8px;
      font-family: Inter, sans-serif;
      font-size: 14px;
    }
  }
}

.modal__footer--copy {
  border-top: none;
}

.add-prizes-header {
  display: flex;
  padding: 13px 0;
  width: 480px;
  justify-content: space-between;
}

.link-small {
  height: 24px;
  padding: 4px 8px;
  font-family: inter-semibold, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  box-sizing: border-box;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  color: #197B6F;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #FFF;
  text-decoration: none;
}

.allocation {
  color: #62698A;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  margin: 0;
  align-self: center;
}
