.modal {
  position: relative;
  z-index: 50;

  &__backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
  }

  &__container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
  }

  &__panel {
    width: 720px;
    padding: 16px;
    border-radius: 16px;
    background: #F9FAFC;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.20);

    h2 {
      margin: 0;
      font-size: 16px;
      color: #4D4E50;
      position: relative;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      font-family: Inter, sans-serif;
    }
  }

  &__close {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }

  &__footer {
    display: flex;
    padding-top: 8px;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    justify-content: space-between;

    &--link {
      font-size: 14px;
      color: #197B6F;
      text-align: center;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 600;
      display: flex;
      align-items: center;
      line-height: 16px;
    }

    &--totals {
      display: flex;
      margin-left: 37px;
      font-size: 12px;
      font-weight: 600;
      font-family: "Inter", sans-serif;
      color: rgba(0, 0, 0, 0.5);

      div {
        width: 100px;
        padding-top: 5px;
        display: flex;
        flex-direction: column;
      }

      .total-value {
        margin-top: 5px;

        &.redeemed, &.reserved {
          color: #FF5F6D;
        }

        &.available {
          color: #444BFF;
        }
      }
    }

    &--btns {
      margin-left: auto;
      display: flex;
      align-items: center;

      .secondary {
        margin-right: 8px;
      }
    }
  }
}

/* Custom modal styles */

.barcode-modal {
  .modal__backdrop {
    background: none;
  }

  .modal__panel {
    width: 600px !important;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  }
}

.codes-list-modal, .codes-edit-modal, .add-to-pool {
  .modal__backdrop {
    background-color: rgba(0, 0, 0, .2);
  }

  .modal__panel {
    width: 760px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  }

  .add-to-pool-table {
    height: 500px;
  }
}

.new-win-modal {
  .modal__panel {
    width: 420px!important;
  }

  &__input {
    display: flex;
    align-items: center;
    margin-bottom: 38px;
  }

  p {
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.5);
    margin: 0;
  }

  &__label {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.6;
    color: rgba(0, 0, 0, 0.5);
    display: block;
    flex-basis: 110px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  textarea {
    height: 160px;
  }

  .dropdown__menu {
    height: 260px;
    overflow-y: auto;
    max-height: 50vh;
  }
}

.import-prizes-modal {

  .modal__panel {
    width: 420px!important;
  }
}

.prizes-modal, .pools-modal {
  .modal__panel {
    width: 760px !important;
    max-height: 600px !important;
  }
  .modal__description {
    min-height: 544px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.html-preview-modal {

  .modal__panel {
    width: 420px !important;
  }

  .html-preview-block {
    width: 388px;
    background-color: #ffffff;
    min-height: 96px;
    padding: 8px;
    margin: 8px 0;
  }
}

.avaliability-modal, .copy-pool {

  .modal__panel {
    width: 420px!important;

    .modal-body {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;

      div {
        width: 190px;
        display: flex;

        p {
          color: #858689;
          font-family: Inter, sans-serif;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          margin: 0;
          padding: 4px;

          &.availablity-value {
            font-weight: 700;
          }
        }
      }
    }
  }
}
