
.switcher {
  display: flex;
  width: 254px;
  height: 32px;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #E8E9EB;

  span {
    display: flex;
    height: 24px;
    padding: 0px 10px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 6px;
    color: #727476;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    cursor: pointer;

    &.active {
      background: #FFF;
    }

    &.inactive {
      background-color: transparent;
    }
  }
}
