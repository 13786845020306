.search {
  position: relative;
  display: inline-block;

  input {
    width: 173px;
    height: 32px;
    background: #E8E9EB;
    border: 0;
    padding: 8px 28px 8px 8px;
    font-size: 14px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.5);

    &.large {
      width: 224px;
    }

    &::placeholder {
      font-size: 14px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.3);
    }

    &:focus,
    &:focus-visible {
      outline: 2px solid rgb(148, 229, 220);
    }
  }

  svg {
	  position: absolute;
	  right: 10px;
	  top: 8px;
	  width: 18px;
	  height: 18px;
	  cursor: pointer;
	  color: #4D4E50;
  }
}
