.prizes {
  padding: 24px;
  border-radius: 16px;
  background: #FFF;

  &__header {
    width: 100%;
    height: 56px;
    padding: 8px 0;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  &__header--left {
    width: 33%;
    display: flex;
    align-items: center;
		justify-content: flex-start;

    p {
      color: #727476;
      font-family: inter-semibold, sans-serif;
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }

    .search {
      margin-left: 24px;
    }
  }

  &__header--right {
    width: 33%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button svg {
      width: 24px;
      height: 24px;
      margin: 0 8px 0 0;
    }
  }

  &__header--center {
    display: flex;
    align-items: center;
    width: 33%;
    justify-content: center;
  }
}

.import-prizes {
  text-align: center;

  p {
    color: #727476;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    margin: 0;
    padding-bottom: 8px;
  }

  .file-upload p {
    padding: 0;
  }

  .sample-file {
    margin-bottom: 16px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: #FFF;
    display: flex;
    width: 160px;
    padding: 4px 8px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    color: #727476;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;

    svg {
      margin: 0;
    }
  }
}

.modal__footer--link {
  cursor: pointer;
}
