.alert-error {
  width: 100%;
  padding: 20px;
  background-color: #FF5F6D;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;

  h3 {
	color: #FFFFFF;
	font-weight: 500;
	border: none;
	margin-bottom: 0;
	margin-top: 0;
  }
}
