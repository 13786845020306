.not-found {
  margin-top: 160px;
  text-align: center;

  h1 {
    font-size: 50px;
    font-weight: 500;
    margin: 40px 0 40px 0;
  }

  p {
    margin-bottom: 40px;
    color: #616161;
    font-size: 20px;
    font-weight: 300;
  }
}