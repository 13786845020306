.empty-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    color: #727476;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin: 8px 0 0 0;
    max-width: 550px;
  }
}