.double-input-wrapper {
  display: flex;
  align-items: center;

  input {
    background: #E8E9EB;
    border: none;
    font-size: 14px;
    line-height: 1;
    color: #4D4E50;
    width: 128px;
    display: flex;
    height: 32px;
    align-items: center;
    align-self: stretch;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    text-overflow: ellipsis;

    &[type=number] {
      padding-right: 4px;
      padding-left: 10px;
    }


    &::placeholder {
      font-size: 14px;
      line-height: 14px;
      color: rgba(55, 63, 65, 0.3);
    }

    &:focus,
    &:focus-visible {
      border: 2px solid rgb(148, 229, 220);
      outline: none;
    }

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  .devider {
    color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    padding: 0 8px 0 8px;
    position: relative;
    top: -3px;
  }

  p {
    padding: 0 8px 0 8px;
    margin: 0;
    color: #9D9EA1;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    position: relative;
    top: -3px;
  }
}

