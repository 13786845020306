.radio {
  display: flex;
  top: -2px;
  position: relative;
  cursor: pointer;
  user-select: none;
}

.radio-outer-circle {
  width: 12px;
  height: 12px;
  min-width: 16px;
  min-height: 16px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  background: #197B6F;
  transition: all 0.1s linear;
}

.radio-inner-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: white;
  transition: all 0.1s linear;
}
.unselected {
  border: 1px solid #4D4E50;
  background: white;
}
.unselected-circle {
  width: 0;
  height: 0;
}
.helper-text {
  color: #666;
  padding-right: 8px;
}
