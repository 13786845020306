nav {
  width: 100%;
  height: 42px;
  background: #F9FAFC;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.navbar {
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  height: 100%;

  &__logo {
    height: 30px;
	  line-height: 38px;
	width: fit-content !important;

	  svg path {
		  fill: #4D4D4D;
		  cursor: pointer;
	  }

	  &:hover {
		  svg path {
			  fill: #197b6f;
		  }
	  }
  }


  &__right {
    list-style: none;
    margin: 0 0 0 32px;
    padding: 0;
    height: 42px;
	width: 100%;
    display: inline-block;

    li {
      display: inline-block;
      height: 42px;

      .link-item {
        padding-left: 8px;
        padding-right: 8px;
        text-decoration: none;
        color: #9D9EA1;
        font-size: 14px;
        font-weight: 700;
        height: 100%;
        line-height: 42px;
        display: block;
        transition: all .4s ease;
		font-family: inter-semibold;

        &:hover {
          color: rgb(7, 88, 78);
        }
      }

	  .active {
		  color: rgb(25, 123, 111);
	  }

	  &:last-child {
		  float: right;
	  }
    }
  }
}


