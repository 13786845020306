.timeline {
  width: 100%;
  height: 308px;
  padding-bottom: 20px;
  overflow-x: auto;

  input {
    margin-bottom: 6px;
    width: 180px;
    color: #4D4E50;
  }

  .table {
    tbody tr td {
      padding: 6px 0 0 6px;

      .middle {
        position: relative;
        top: -3px;
      }

      svg {
        width: 16px;
        height: 16px;
      }

      .label-inside {
        position: relative;
        display: flex;
        overflow: hidden;

        span {
          position: absolute;
          color: #9D9EA1;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          top: 8px;
          left: 104px;
          font-family: Inter, sans-serif;
          white-space: nowrap;

          text-overflow: ellipsis
        }

        p {
          margin: 0;
          top: 9px;
          left: 8px;
          position: relative;
          color: #9D9EA1;
          font-family: Inter, sans-serif;
          font-size: 14px;
          font-weight: 400;
        }

        &__prizes {
          span {
            left: 130px;
          }
          input {
            padding-right: 54px;
          }
        }

        &__double-plays {
          span {
            left: 84px;
          }
          input {
            padding-right: 46px;
          }
        }
      }
    }

    thead th {
      padding: 6px 0 0 6px;

      &:last-child {
        width: 180px;
      }
    }
  }
}

.label-inside__plays__wrap{
  display: flex;
  align-items: center;
p{
  margin: 0 0 6px 10px;
}
}
