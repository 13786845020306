.textarea {
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 14px;
  line-height: 1;
  color: rgba(0, 0, 0, 0.5);
  padding: 8px;
  width: 100%;
  height: 90px;
  display: block;

  &::placeholder {
	font-size: 14px;
	line-height: 14px;
	color: rgba(55, 63, 65, 0.3);
  }

  &:focus,
  &:focus-visible {
    outline: none;
    border: 2px solid rgb(148, 229, 220);
  }

  &.in-pools {
    max-width: 725px;
    min-width: 725px;
    max-height: 48px;
    min-height: 32px;
  }

  &.in-prizes {
    max-width: 360px;
    min-width: 360px;
    max-height: 96px;
    min-height: 32px;
  }

  &.in-winners {
    max-width: 388px;
    min-width: 388px;
    height: 118px;
    min-height: 118px;
    max-height: 240px;
  }
}
