.checkbox {
  cursor: pointer;
  position: relative;
  align-items: center;
  margin-right: 10px;
  display: block;
}

.checkbox > span {
  font-family: Inter, sans-serif;
  color:  #4D4E50;
  padding: 0 0 0 10px;
  font-size: 14px;
  font-weight: 400;
}

.checkbox > input {
  height: 16px !important;
  width: 16px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #A5A5A5;
  outline: none;
  transition-duration: 0.3s;
  background-color: #FFFFFF;
  cursor: pointer;
  border-radius: 4px;
}

.checkbox > input:checked {
  border: 1px solid #FFFFFF;
  background-color: #197B6F;
}

.checkbox > input:checked + span::before {
  display: block;
  text-align: center;
  color: #FFFFFF;
  position: absolute;
  top: 0;
  content: url("../../../../src/assets/icons/check.svg");
}

.checkbox input:disabled {
  opacity: 0.6;
  cursor: default;
}
