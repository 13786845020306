.confirm {
  position: relative;
  z-index: 50;

  &__backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
  }

  &__container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
  }

  &__panel {
    width: 400px;
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 16px;
    background: #F9FAFC;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.20);

    h2 {
      margin: 0;
      padding-bottom: 8px;
      font-size: 16px;
      color: #727476;
      position: relative;
			font-style: normal;
			font-weight: 600;
			line-height: 24px;
    }

    svg {
      width: 16px;
      height: 16px;

      &.primary {
        path {
          fill: #88C559;
        }
      }

      &.danger {
        path {
          fill: #FF5F6D;
        }
      }
    }
  }

  &__close {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }

  &__description {
		width: 100%;
		display: flex;
		justify-content: flex-start;

		p {
			margin: 0;
			color: #858689;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 16px;
		}
  }

  &__footer {
		width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
