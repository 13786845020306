.in-campaign {
  .info {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0;
    color: #858689;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    padding: 16px 0;
  }

  &__header {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    justify-content: flex-start;

    button {
      margin-left: 14px;
    }
  }

  &__body {
    height: 369px;

    .campaign-fields {

      &__summary {
        display: flex;
        justify-content: flex-start;
        padding: 4px 8px;

        &.last {
          border-bottom: 1px solid rgba(0, 0, 0, 0.15);
          padding-bottom: 16px;
        }
      }

      &__name {
        width: 50%;
        color: #62698A;
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        margin: 0;
      }

      &__value {
        color: #727476;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        margin: 0;
        max-width: 350px;
      }

      &__remove {
        margin-top: 8px;
        border: none;
        background: transparent;
        font-size: 14px;
        font-weight: 600;

        svg {
          margin-right: 4px;
          path {
            fill: #197B6F;
          }
        }
      }
    }

    a {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #88C559;
      display: block;
      margin-top: 20px;
      text-decoration: none;
    }
  }
}
