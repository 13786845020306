.barcode {
  display: flex;
  justify-content: center;

  svg {
    padding: 16px;
  }

  .barcode__text {
    padding: 1rem 0;
    font-weight: 600;
    font-size: 1.5rem;
  }
}
