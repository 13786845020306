.btn-table--resend {
  width: 7.5rem;
  max-width: 7.5rem;
}

.winner-search-table {
  color: #4D4E50;
  font-family: inter-semibold, sans-serif;
    font-weight: 400;
  font-size: 14px;
  font-style: normal;
  line-height: 16px;
}

.modal__no-prizes-available {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.6;
  color: rgba(0, 0, 0, 0.5);
  padding: 8px;
}

.new-win-modal {
  .modal__panel {
    h2 {
      padding-bottom: 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }
  }

  .modal__description {
    margin-top: 16px;

    .modal__footer {
      padding-top: 16px;
    }
  }

  &__input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;

    textarea {
      color: #4D4E50;
      background: #E8E9EB;
      font-family: Inter, sans-serif;
      height: 96px;
      border-radius: 0;
    }

    p {
      font-family: Inter, sans-serif;
      font-size: 14px;
      color: #727476;
      font-weight: 400;
    }

    .dropdown {
      width: 100%;
    }

    .dropdown__btn, {
      margin: 0;
      width: 100% !important;
      color: #4D4E50;
      background-color: #E8E9EB;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px
    }

    .dropdown__menu {
      width: 100% !important;
      height: auto !important;
    }
  }

  &__label {
    color: #4D4E50;
    flex-basis: 0;
    margin-bottom: 5px;
    font-size: 14px;
  }
}

.prize-code {
  color: #197B6F;
  display: flex;
  align-items: center;

  span {
    margin-left: 8px;
  }
}
