.pagination {
  border-radius: 5px;
  background: #FCFCFC;

  td {
    padding: 5px 0;
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-top: 20px;

    &--left {
      font-size: 12px;
      line-height: 12px;
      color: #4D4E50;
      padding-left: 12px;
    }

    &--right {
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        border-radius: 4px 0px 0px 4px;

        li {
          display: flex;
          align-items: center;
          margin-right: 0px;

          button {
            border: none;
            margin: 0;
            cursor: pointer;
            display: flex;
            height: 24px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex: 1 0 0;
            color: #4D4E50;
            text-align: center;
            font-family: inter-semibold, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            border-right: 1px solid #EAEEEF;
            border-left: 1px solid #EAEEEF;
            background: #FFF;

            svg {
              height: 24px;
            }

            &.link {
              width: 24px;
            }

            &.number {
              min-width: 24px;
              padding: 7px 4px;

              &.active {
                border-right: 0.5px solid #EAEEEF;
                background: #EAEEEF;
              }
            }

            &.disabled {
              pointer-events: none;
              opacity: .6;
            }
          }

          .dots {
            color: black;
            width: 24px;
            text-align: center;
          }
        }
      }
    }
  }
}
