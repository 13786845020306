.dropdown {
  position: relative;


  &.multi {
    width: 240px;

    &__btn {
      max-width: 240px;
    }
  }


  &__btn {
    background: #E8E9EB;
    border: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #4D4E50;
    position: relative;
    cursor: pointer;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    height: 32px;
    padding: 10px 8px;
    justify-content: space-between;
    align-items: center;

    svg {
      margin-top: 4px;
    }

    &[aria-expanded="true"]{
      border: 2px solid rgb(148, 229, 220);
    }

    &:focus,
    &:focus-visible {
      border: 2px solid rgb(148, 229, 220);
    }
  }

  &__menu {
    list-style: none;
    position: absolute;
    background: #FFFFFF;
    box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 0;
    margin: 0;
    width: 100%;
    z-index: 999;

    &--item {
      padding: 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 1;
      color: #4D4E50;
      cursor: pointer;
      transition: all .2s;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #FFF;

      &:hover {
        background-color: #D6D7D9;
      }

      &.selected {
        &:hover {
          background: #E8E9EB;
        }

        svg path {
          stroke: #727476;
        }
      }

      &.disabled {
        color: rgba(0, 0, 0, 0.2);

        &:hover,
        &.active {
          background: none;
          color: rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}
