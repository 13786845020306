.inside-pool {
  .info {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0;
    color: #858689;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    padding-bottom: 16px;
  }

  &__header {
	display: flex;
	margin-bottom: 16px;
	align-items: center;
	justify-content: flex-end;
  }

  &__body {
    height: 363px;

    .table-wrapper {
      overflow-y: auto;
      height: 363px;
    }
  }
}
