.search-autocomplete {
  width: 360px;

  &__menu {
    list-style: none;
    position: absolute;
    background: #FFFFFF;
    box-shadow: 8px 8px 20px rgb(0 0 0 / 20%);
    border-radius: 5px;
    padding: 0;
    margin: 0;
    z-index: 999;
    max-height: 300px;
		width: 360px;
    overflow-x: auto;

    &[aria-expanded="true"] {
      border: 2px solid rgb(148, 229, 220);
    }

    li {
			padding: 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 1;
      color: #4D4E50;
      cursor: pointer;
      transition: all .2s;
      height: 40px;
      display: flex;
			justify-content: space-between;
			background: #FFF;

			&:hover {
				background-color: #D6D7D9;
			}

			&.selected {
				&:hover {
					background: #E8E9EB;
				}

				svg path {
					stroke: #727476;
				}
			}

			&.disabled {
				color: rgba(0, 0, 0, 0.2);

				&:hover,
				&.active {
					background: none;
					color: rgba(0, 0, 0, 0.2);
				}
			}
    }
  }
}
