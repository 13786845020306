.prize-details {
  display: block;
  font-family: Inter, sans-serif;
  max-height: 460px;

  &__top {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 4px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);

    p {
      color: #727476;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      width: 100%;
      margin: 0;
      padding: 16px 0 8px 0;
    }

    .info {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      padding: 0;

      p {
        padding: 8px 2px;
      }
    }

    .input-group {
      width: 360px;
      display: flex;
      flex-direction: column;

      &__small {
        width: 176px;
      }

      textarea {
        height: 96px;
        padding: 8px;
        background: #E8E9EB;
        border-radius: 0;
        border: none;
        margin-top: 4px;
        font-family: Inter, sans-serif;

        &:focus,
        &:focus-visible {
          outline: 2px solid rgb(148, 229, 220);
        }
      }

      input {
        height: 32px;
        padding: 8px;
        background: #E8E9EB;
        border-radius: 0;
        margin-top: 4px;
        font-family: Inter, sans-serif;

        &:focus,
        &:focus-visible {
          outline: 0;
        }
      }

      label {
        color: #4D4E50;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }

      .preview-html {
        display: flex;
        align-items: center;
        color: #197B6F;
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-weight: 600;
        align-self: end;
        padding-top: 4px;
        cursor: pointer;
      }

      .required {
        color: #DD3F3F;
        font-family: Inter, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px;
        margin-left: 4px;
      }

      .label-inside {
        position: relative;

        input {
          padding-right: 48px;
        }
        &:before {
          position: absolute;
          top: 11px;
          right: 14px;
          color: #9D9EA1;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
        }

        &__days:before {
          content: "days";
        }
      }
    }
  }

  &__bottom {
    width: 100%;
    height: 162px;
    display: flex;
    justify-content: space-between;
    padding-top: 16px;

    &--input:last-child {
      display: flex;
      align-items: center;
      justify-content: right;

      input {
        width: 50px;
        margin-right: 20px;
        margin-left: 20px;
      }

      span {
        font-size: 16px;
        line-height: 1.6;
        color: rgba(0, 0, 0, 0.5)
      }
    }
  }
}
