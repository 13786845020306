.codes-container {
  .search-header {
    display: flex;
    margin: 16px 0;
    justify-content: space-between;

    .search input {
      width: 480px;
    }
  }

  .codes-list {
    height: 446px;

    &__header {
      margin-bottom: 4px;
      border-bottom: 1px solid #D9D9D9;

      th {
        color: #62698A;
        font-family: Inter, sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-align: left;
        padding: 8px;
        white-space: nowrap;
      }
    }

    &__body {
      max-height: 410px;
      overflow: auto;
      margin-bottom: 30px;
    }

    &__row {
      display: flex;
      justify-content: space-between;
      padding: 8px;
      border-bottom: 1px solid #D9D9D9;
      align-items: center;

      &:hover > div {
        opacity: 0.5;
      }
    }

    &__link {
      font-size: 14px;
      opacity: 0;
      text-transform: uppercase;
      cursor: pointer;
    }

    &__name {
      font-size: 14px;
      color: #4D4E50;
      cursor: default;
    }
  }
}


