@font-face {
  font-family: inter-regular;
  src: url(https://brame-static.s3.eu-central-1.amazonaws.com/libraries_and_fonts/fonts/inter/Inter-Regular.ttf);
}

@font-face {
  font-family: inter-semibold;
  font-weight: 700;
  src: url(https://brame-static.s3.eu-central-1.amazonaws.com/libraries_and_fonts/fonts/inter/Inter-SemiBold.ttf);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background: #F2F2F2;
  font-family: Inter, sans-serif;
}

body * {
  box-sizing: border-box;
}

.mr-10 {
  margin-right: 10px;
}

.pr-20 {
  padding-right: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  width: 100%;
  padding-left: 15%;
  padding-right: 15%;
}

.container-lg {
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
}

.elipsis-275 {
  display: inline-block;
  max-width: 275px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.elipsis-200 {
  display: inline-block;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.elipsis-180 {
  display: inline-block;
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.elipsis-140 {
  display: inline-block;
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.elipsis-100 {
  display: inline-block;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.red {
  color: #FF5F6D;
}

.cursor-pointer {
  cursor: pointer;
}

.m-auto {
  margin: 0 auto;
  display: block;
}

.brame-tooltip {
  opacity: 1!important;
  background-color: #88c559!important;
  border-radius: 5px!important;
  text-align: center;
}

.error-message {
  color: #FF5F6D;
  display: block;
  font-size: 12px;
  line-height: 15px;
  margin: 5px 0 0 2px;
  text-align: left;
}

input {
  &:focus,
  &:focus-visible {
    outline: none;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}


@import-normalize
