.file-upload {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: inter-semibold, sans-serif;
  padding-top: 8px;
  padding-bottom: 9px;

  input[type="file"] {
    display: none;
  }

  input[type="text"] {
    width: 415px;
  }

  label {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    box-sizing: border-box;
    box-shadow: none;
    cursor: pointer;
    display: flex;
    height: 32px;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    color: #197B6F;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: #FFF;

    &:hover {
      background: #F9FAFC;
    }

    &.file {
      color: #727476;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      justify-content: space-between;

      .csv_icon {
        margin-right: 8px;
      }

      .close_icon {
        margin-left: 24px;
      }
    }

    p {
      width: 128px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  p {
    color: #727476;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
      margin-left: 8px;
  }
}
