.codes {
  padding-top: 16px;

  &__description {
    color: #858689;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
    padding-bottom: 16px;
  }

  &__body {
    height: 366px;
    overflow: auto;

  }

  &__header {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
    align-items: center;

    &--dropdown {
      width: 240px;
    }
  }
}

.duplicate-codes__dialog {
  .duplicate-codes__message {
    font-size: 16px;
    line-height: 1.6;
    color: rgba(0, 0, 0, 0.5);
  }

  .modal__footer {
    border-top: none;
  }
}
