.codes-upload {
  padding: 10px 0px 8px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);

  &.loading {
    opacity: 0.5;
  }

  &__top {
    display: flex;
    justify-content: space-between;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    color: #4D4E50;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    margin: 0 0 12px 0;
    display: flex;
    align-items: center;
  }

  &__view, &__delete, &__save {
    font-family: Inter, sans-serif;
    cursor: pointer;
    margin: 0 0 12px 0;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    display: flex;
    height: 24px;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: none;
    background: none;

    &:disabled {
      cursor: not-allowed;
    }
  }

  &__save {
    padding-left: 8px;
    border-left: 1px solid rgba(0, 0, 0, 0.15);
    color: #197B6F;

    &.disabled {
      color: #B3B4B7;

      svg path {
        stroke: #B3B4B7;
      }
    }
  }


  &__view {
    svg path {
      fill: #197B6F;
    }

    &.disabled {
      svg path {
        fill: #B3B4B7;
      }
    }
  }

  &__delete {
    svg path {
      fill: #197B6F;
    }

    &.disabled {
      svg path {
        fill: #B3B4B7;
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .codes-upload__label {
      color: #4D4E50;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      position: relative;
      bottom: 4px;
    }

    .codes-expiration {
      display: block;
      width: 160px;
      margin-left: 2px;

      .react-datepicker-wrapper {
        width: auto;

        input {
          background: #E8E9EB;
          border: none;
          font-size: 14px;
          line-height: 1;
          color: rgba(0, 0, 0, 0.5);
          width: 100%;
          display: flex;
          height: 32px;
          padding: 10px 16px 10px 8px;
          align-items: center;
          align-self: stretch;

          &:focus,
          &:focus-visible {
            outline: 2px solid rgb(148, 229, 220);
          }
        }
      }

      .react-datepicker__day {
        &--keyboard-selected {
          background-color: #197B6F;
        }

        &--selected {
          background-color: #197B6F;
        }
      }
    }

    .codes-validity {
      display: block;
      width: 160px;

      input {
        padding-right: 40px;
      }

      span {
        position: relative;
      }

      span:before {
        position: absolute;
        content: "min";
        top: 26px;
        left: 43px;
        color: #9D9EA1;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
      }
    }

    .codes-dropdown {
      display: block;
      width: 160px;

      button {
        width: 100%;

        &:focus {
          outline: none;
        }
      }
    }

    .file-upload {
      width: 160px;
      padding: 0;

      label {
        width: 100%;
      }
    }
  }

  &__sum {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    div {
      width: 160px;
      display: flex;

      p {
        color: #727476;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        margin: 16px 0 0 0;

        &:nth-child(2) {
          margin-left: 8px;
        }
      }
    }
  }
}

