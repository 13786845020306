.table-wrapper {
  overflow-y: hidden;
  overflow-x: auto;
}

.table {
  border-collapse: collapse;
  width: 100%;
  background: #F9FAFC;
  font-family: inter-semibold, sans-serif;

  thead {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    height: 40px;
    padding: 8px 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    background: #F9FAFC;
    z-index: 1;

    th {
      text-align: left;
      padding: 8px 18px 8px 16px;
      white-space: nowrap;
      color: #62698A;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;

      svg {
        position: relative;
      }

      .info-icon {
        right: -12px;
        top: 6px;
        width: 15px;
      }

      .sort-icon {
        right: -6px;
        top: -2px;
        cursor: pointer;

        path {
          fill: #C4C9E0;
        }

        &.active {
          path {
            fill: #62698A;
          }
        }
      }
    }

    th:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    th:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  tbody {
    tr {
      height: 40px;

      td {
        padding: 3px 18px 3px 16px;
        color: rgba(0, 0, 0, 0.5);
        white-space: nowrap;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        vertical-align: middle;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;

        img {
          width: 32px;
          height: 32px;
          border: 1px solid rgba(0, 0, 0, 0.20);
          border-radius: 4px;
          position: relative;
          top: 1px;
        }

        &.no-data {
          padding: 15px;
          font-size: 14px;
        }

        .edit-btn {
          cursor: pointer;
          margin-left: 16px;
        }

        .delete-btn {
          cursor: pointer;
        }

        .actions svg {
          margin-right: 8px;
        }
      }
    }

    .expanded-row {
      .codes-link {
        margin: 2px;
        color: #88C559;
        text-decoration: none;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.with-scroll {
  max-height: 288px;
  overflow-y: auto;
}

.no-image-table {
  tbody {
    tr {
      td {
        padding: 15px;
      }
    }
  }
}

.white-space-normal {
  thead {
    th {
      white-space: normal;
    }
  }
}

// Custom styles
.activity {
  border-radius: 200px;
  padding: 3px 7px;
  display: inline-block;
  text-transform: capitalize;
  color: #4D4E50;

  &.active {
    background-color: #D6FABC;
  }

  &.inactive {
    background-color: #E8E9EB;
  }

  &.draft, &.wallet {
    background-color: #C1DDFA;
  }

  &.default {
    background-color: #FFF8B3
  }
}

