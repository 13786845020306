.winner-search {
  padding-top: 60px;
  padding-bottom: 60px;

  h2 {
    font-weight: 400;
    font-size: 20px;
    line-height: 1.6;
    color: #9F9F9F;
  }

  &__container {
    padding: 24px;
    border-radius: 16px;
    background: #FFF;
  }

  &__header {
    width: 100%;
    border-radius: 5px;
    padding: 8px;
    display: flex;
    align-items: center;

    p {
      color: #727476;
      font-family: inter-semibold, sans-serif;
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin-right: 8px;
    }
  }
}
